







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ApexOptions } from 'apexcharts';
import { BrandQueryResultItemScore } from "@/api/braendz";

export interface ValueColor {
    color: string;
    limit: number;
}

@Component({
    components: {}
})
export default class BrandSimilaritiesBarChart extends Vue {
    // Member:

    // Properties:
    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    @Prop({required: true})
    public score!: BrandQueryResultItemScore;

    // Watcher

    // Getter:
    public get autoHeight(): number {
        return this.options.labels?.length ? this.options.labels.length * 20 + 80 : 80;
    }

    public get options(): ApexOptions {
        const data: number[] = [];
        const labels: string[] = [];
        const colors: string[] = [];

        if(this.score.nameSimilarityScore) {
            data.push(this.score.nameSimilarityScore * 100);
            labels.push('Name');
            colors.push('#ff6600');
        }
        if(this.score.logoSimilarityScore) {
            data.push(this.score.logoSimilarityScore * 100);
            labels.push('Logo');
            colors.push('#1ac57e');
        }
        if(this.score.goodsServicesSimilarityScore) {
            data.push(this.score.goodsServicesSimilarityScore * 100);
            labels.push('Goods & Services');
            colors.push('#f2f2f2');
        }

        if(this.score.niceClassesCoverage) {
            data.push(this.score.niceClassesCoverage * 100);
            labels.push('Nice Classes Coverage');
            colors.push('#ffcc00');
        }


        const result: ApexOptions = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: true
                }
            },
            series: [{ data: data }],
            colors: colors,
            labels: labels,
            tooltip: {
                enabled: false
            },
            fill: {
                colors: colors,
            },
            legend: {
                show: false
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadiusApplication: 'around',
                    borderRadius: 4,
                    barHeight: '15px',
                    dataLabels: {
                        position: 'bottom'
                    },
                    distributed: true
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: number, opt: any) {
                    return `${opt.w.globals.labels[opt.dataPointIndex]}`;
                },
                offsetX: 25,
            },
            grid: {
                show: true,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                }
            },
            xaxis: {
                labels: {
                    show: true,
                },
                stepSize: 100,
                position: 'bottom',
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            }
        };

        return result;
    }

    // Component Lifecycle Methods:

    // Methods:
}
