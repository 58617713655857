







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ApexOptions } from 'apexcharts';

export interface ValueColor {
    color: string;
    limit: number;
}

@Component({
    components: {}
})
export default class RadialBar extends Vue {
    // Member:

    // Properties:
    @Prop({required: false})
    public height?: number | string;

    @Prop({required: false})
    public width?: number | string;

    @Prop({required: false})
    public label?: string;

    @Prop({required: true})
    public value!: number;

    @Prop({required: false})
    public color?: string;

    @Prop({required: false})
    public valueColors?: ValueColor[];

    @Prop({required: false, default: false, type: Boolean})
    public showTooltip?: boolean;

    @Prop({required: false, default: false, type: Boolean})
    public showValue!: boolean;

    @Prop({required: false})
    public endAngle?: number;

    @Prop({required: false})
    public startAngle?: number;

    // Watcher

    // Getter:
    public get options(): ApexOptions {
        const result: ApexOptions = {
            chart: {
                type: 'radialBar',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: true
                },
                offsetY: -5
            },
            series: [ this.value ],
            colors: this.color ?? this.getColorByValue(this.value) ? [ this.color ?? this.getColorByValue(this.value) ] : undefined,
            labels: this.label ? [ this.label ] : undefined,
            tooltip: {
                enabled: this.showTooltip
            },
            plotOptions: {
                radialBar: {
                    startAngle: this.startAngle,
                    endAngle: this.endAngle,
                    dataLabels: {
                        name: {
                            show: !this.showValue,
                            fontSize: '14px',
                            fontWeight: 'bold',
                            offsetY: 5
                        },
                        value: {
                            show: this.showValue,
                            offsetY: 5,
                            fontSize: '14px',
                            fontWeight: 'bold',
                            formatter: function(val) {
                                return Math.round(val) + '%';
                            },
                            color: this.color ?? this.getColorByValue(this.value)
                        }
                    },
                    hollow: {
                        size: '30%'
                    },
                    track: {
                        show: true,
                        opacity: 0.2,
                        background: this.color ?? this.getColorByValue(this.value) ?? '#000000'
                    }
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            }
        };

        return result;
    }

    // Component Lifecycle Methods:

    // Methods:
    public getColorByValue(value: number): string | undefined {
        return this.valueColors?.find(cs => value <= cs.limit)?.color;
    }
}
