import { render, staticRenderFns } from "./BrandSimilaritiesBarChart.vue?vue&type=template&id=fc7e395c&scoped=true&"
import script from "./BrandSimilaritiesBarChart.vue?vue&type=script&lang=ts&"
export * from "./BrandSimilaritiesBarChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc7e395c",
  null
  
)

export default component.exports