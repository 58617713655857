













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Label extends Vue {
    // Properties
    @Prop({ required: true })
    public text!: string;

    @Prop({ required: false, default: 'top' })
    public location!: 'top' | 'bottom';

    @Prop({ required: false, default: 'left' })
    public align!: 'left' | 'right' | 'center';

    @Prop({ required: false, default: 'start' })
    public justify!: 'start' | 'end' | 'center';

    @Prop({ required: false })
    public offsetY?: number;
}
